.chatMessageBox {
    overflow-y: auto;
    height: 500px;
    display: flex;
    flex-direction: column-reverse;
    padding: 5px;

    &.empty {
        justify-content: center;
        text-align: center;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.1);
        font-weight: bold;
        text-transform: uppercase;
    }

    &::-webkit-scrollbar {
        width: 12px; /* for vertical scrollbars */
        height: 12px; /* for horizontal scrollbars */
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
    }

    .chatMessageContainer {
        flex-direction: column;
        display: flex;

        .playerNameSmall {
            font-size: 11px;
            color: #fff;
        }

        .chatMessage {
            margin-bottom: 5px;
            background: #e4e6eb;
            padding: 3px 8px;
            border-radius: 8px;
            color: #050505;
            display: flex;
            width: fit-content;
            max-width: 90%;
            white-space: pre-wrap;
            position: relative;
            margin-left: 30px;
            word-break: break-word;

            .userPic {
                position: absolute;
                left: -30px;
                bottom: 2px;
            }

            .msg {
                max-width: calc(100% - 36px);
                display: contents;
                word-wrap: break-word;
            }

            &.isMe {
                .userPic {
                    display: none;
                }
                background: #a000ff;
                align-self: flex-end;
                color: #fff;
            }
        }
    }
}

.messageInput {
    background: #ffffff9e;
    border: none;
    color: #050505;
    margin-top: 10px;
    width: calc(100% - 30px);
    margin-left: 15px;
}

.emojiButton {
    position: absolute;
    bottom: 18px;
    left: 15px;
}

.emoji-mart {
    position: absolute;
    bottom: 0;

    .emoji-mart-preview {
        display: none;
    }
}

@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;