#team {
    .main_team_row {
        margin: 10px !important;
        justify-content: center;
    }

    .captain_color {
        color: @primary-color;
    }

    .regular_event {
        border: 1px solid #eaeaea;
        padding: 5px;
        border-radius: 15px;
        background: #fbfbfb;
    }

    .team {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin: auto;
        text-align: center;
        padding: 20px;

        .player {
            overflow: hidden;

            .shirt {
                max-width: 100px;
                align-self: center;
            }

            .playerNameContainer {
                .playerName {
                    white-space: nowrap;
                    background: rgba(255, 255, 255, 0.9);
                    display: inline-block;
                    padding: 5px 10px;
                    border-radius: 3px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 80%;
                    border: 1px solid #ececec;
                    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
                    position: relative;
                    top: -20px;
                    font-weight: 600;
                }
            }
        }
    }

    @media (max-width: 500px) {
        .main_team_row {
            margin: 4px !important;

            .event_card {
                .ant-card-body {
                    padding: 10px 7px;

                    .ant-picker-cell-inner {
                        padding: 4px 4px 0;
                    }
                }
            }
        }
    }
}

@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;