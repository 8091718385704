.HeroSection {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.hero_overlay {
        content: '';
        position: absolute;
        background: rgba(255, 255, 255, 0.46);
        width: 100%;
        height: 100%;
    }

    .innerContainer {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: row;
        flex: 1;

        .mainContent {
            width: 100%;
            color: #fff;
        }
    }
}
@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;