.notification_preview {
    background-image: url(/static/images/phone.png);
    background-position: top;
    background-size: 400px;
    background-repeat: no-repeat;
    height: 142px;

    @media (max-width: 500px) {
        background-size: 330px;
    }
}
.notification_preview_text {
    .phone_info_top {
        color: #ababab;
        font-weight: bold;
        font-size: 10px;
    }

    background-image: url(/static/images/phone_bg.png);
    background-position: bottom;
    background-size: 400px;
    background-repeat: repeat-y;
    margin: auto;
    text-align: center;
    .inner_text {
        background: #e6e6e8;
        color: rgba(0, 0, 0, 0.65);
        display: inline-block;
        width: 200px;
        padding: 10px;
        text-align: left;
        position: relative;
        left: -50px;
        border-radius: 9px;
        margin: 10px 0px;
        word-break: break-word;

        @media (max-width: 500px) {
            width: 165px;
            left: -40px;
        }
    }

    @media (max-width: 500px) {
        background-size: 330px;
    }
}

@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;