.shirt_template_select {
    width: 100%;
    flex: 1;
    height: 61px;

    .ant-select-selector {
        width: 100% !important;
        height: 57px !important;
        padding: 0 11px !important;

        .ant-select-selection-item {
            text-align: center;

            img {
                padding-top: 5px;
                width: 50%;
            }
        }

    }
}

.shirt_template_color_picker {
    position: relative;

    .color_swatch_container {
        .color_swatch {
            height: 47px !important;
            width: 100% !important;
        }

        width: 100%;
    }
}

.flex-space {
    display: flex !important;
    width: 100%;

    .ant-space-item {
        flex: 1 !important;
        height: 57px;
    }

    .ant-space-item:first-child {
        flex: 2 !important;
    }
}

.grid_select>div:nth-child(1)>div>div>div {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
}
@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;