.fb_iframe_widget * {
    text-align: center;
}

#session {
    // .time_and_location {
    //     background: linear-gradient(to right, #7f00ff, #e100ff);
    //     color: #fff;

    //     h1,
    //     h2,
    //     h3,
    //     h4,
    //     h5,
    //     h6 {
    //         color: #fff;
    //     }
    // }

    hr {
        border: 1px solid rgba(255, 255, 255, 0.24);
    }

    .white_message {
        color: #fff;
        background: transparent;
        border: 1px solid #fff;
        .ant-alert-message {
            color: white;
        }
    }

    .ant-alert-with-description.ant-alert-no-icon {
        padding: 5px 5px;
        font-size: 10px;

        .ant-alert-with-description .ant-alert-message {
            font-size: 14px;
        }

        .ant-alert-description {
            font-size: 12px;
            line-height: 16px;
        }
    }

    .count_block {
        color: white;
        font-weight: bold;
        padding: 2px 5px;
        border-radius: 3px;
        display: inline-block;
        text-align: center;
        margin: 0 2px;

        &.confirmed {
            background: @success-color;
        }

        &.needed {
            background: @warning-color;
        }
    }

    .sms_info {
        background: transparent;

        .ant-space-item {
            width: 100%;
        }

        .ant-card-extra {
            color: #fff !important;
        }

        .ant-table,
        .ant-table-row {
            background: rgba(255, 255, 255, 0.12);
            color: #fff;

            &:hover {
                background: rgba(255, 255, 255, 0.18);
            }

            .ant-table-cell {
                &:hover {
                    background: rgba(255, 255, 255, 0.18);
                }

                &:last-child {
                    border: none;
                }
            }
        }
    }

    .session_warning {
        background: fade(@warning-color, 7%);
        text-align: center;
        padding: 20px;
        color: @warning-color;
        max-width: 420px;
        margin: auto;
        border-radius: 7px;
        margin-bottom: 10px;
    }

    .transparent_box {
        background: transparent;
        color: #fff;
        border: none;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .ant-timeline {
            color: #fff;
        }
    }

    .location_image {
        width: 100px !important;
        margin: auto;

        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.21);
    }

    .weekday {
        text-transform: capitalize;
    }

    .sessionTabs {
        .ant-tabs-ink-bar {
            background: #fff;
        }

        .ant-tabs-tab {
            &:hover {
                color: #e2e2e2;
            }

            &.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #fff !important;
            }
        }
    }

    .team {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        margin: auto;
        text-align: center;
        padding: 20px;

        @media (max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 540px) {
            padding: 0;
            grid-gap: 0 10px;
        }

        .player {
            overflow: hidden;
            position: relative;

            .confirmationButtons {
                position: absolute;
                z-index: 2;
                width: 100%;
                top: 30px;
            }

            .shirt {
                max-width: 100px;
                align-self: center;
            }

            .playerNameContainer {
                position: relative;
                width: 100%;

                .playerStatus {
                    position: absolute;
                    background: white;
                    padding: 3px 9px;
                    bottom: 75px;
                    width: 100%;
                    font-size: 12px;
                }

                .playerNameAndStatus {
                    white-space: nowrap;
                    background: rgba(255, 255, 255, 0.9);
                    display: inline-block;
                    padding: 5px 10px;
                    border-radius: 3px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    border: 1px solid #ececec;
                    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
                    position: relative;
                    top: -20px;

                    .playerName {
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-transform: capitalize;
                        color: inherit;
                    }
                }
            }
        }
    }
}

@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;