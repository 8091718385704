.header {
    z-index: 1;
    background: linear-gradient(to right, #7f00ff, #e100ff);
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);

    @media (max-width: 500px) {
        padding: 0 10px;
    }

    .logo {
        width: 57px;
        height: 64px;
        // background: url(/static/images/logo/logo_light_wide.png);
        background-size: contain;
        margin: auto;
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;
    }

    .main_menu {
        line-height: 62px !important;
        margin-left: 58px !important;
        display: flex;
        background: none;
    }

    .ant-menu-submenu {
        padding: 0 !important;
    }

    .ant-menu-overflowed-submenu {
        margin-left: auto;
    }

    .hide-group-title {
        .ant-menu-item-group-title {
            display: none;
        }
    }
}

@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;