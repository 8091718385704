.cancel_linethough {
    text-decoration: line-through;
    color: #ff220c !important;
}

#frontpage_hero_section {
    background: linear-gradient(to right, #7f00ff, #e100ff);
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
    }

    h1 {
        font-size: 3em;
    }

    .team_example {
        max-width: 550px;
        position: relative;
        box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        border: 1px solid #d5d5d5;
        margin: auto;
    }

    #bottom_divider {
        position: absolute;
        width: 100%;
        padding-bottom: 25%;
        bottom: 0;
        overflow: hidden;

        #bottom_divider_inner {
            background: white;
            transform: rotate(-5deg);
            height: 100%;
            position: absolute;
            width: 120%;
            bottom: -20%;
            left: -10%;
        }
    }

    .logo_container {
        background: white;
        display: inline-block;
        height: 140px;
        width: 140px;
        border-radius: 100%;
        padding: 25px;
        margin-top: 30px;
    }
}

#event_calendar {
    .ant-picker-calendar-date-content {
        font-size: 11px;
    }

    .event_icon {
        position: absolute;
        bottom: 0;
        left: 15px;
    }

    .frontpage_calendar_card {
        .ant-card-body {
            padding: 0;
        }

        @media (max-width: 1000px) {
            .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
                margin: 0 2px;
                padding: 2px 4px 0;
            }
        }
    }
}

#notifications_section {
    .innerContainer {
        align-items: flex-end;
    }
}

.frontpage_card,
.frontpage_calendar {
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
    background: #fff;
}

.featured_shirt {
    width: 15%;
    display: inline-block;
    padding: 20px;
    max-width: 220px;

    .shirts_container {
        position: relative;

        .featured_away_kit {
            position: absolute;
            width: 46%;
            bottom: 0;
            right: 5px;
        }
    }

    .featured_team_info {
        color: #8e8e8e;
    }

    .featured_team_name {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;
    }
}

@media (max-width: 900px) {
    .featured_shirt {
        width: 50%;
    }
}

@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;