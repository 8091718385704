.autocomplete-dropdown-container {
    position: absolute;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
    z-index: 9999;

    .suggestion-item {
        padding: 7px;
        background-color: #ffffff;
        cursor: pointer;

        &:nth-child(even) {
            background-color: #f9f9f9;
        }

        &:hover {
            background-color: #e6e6e6;
        }
    }
}
@primary-color: #e100ff;@error-color: #FF220C;@warning-color: #EE8434;@success-color: #3aca8b;